.wrapper {
  width: 100%;

  display: flex;

  flex-direction: column;

  align-content: center;
  align-items: center;
  
  color: var(--secondary-text-color);

  overflow-x: visible;
  overflow-y: visible;
}
.wrapper * {
  margin: 0;
  padding: 0; 

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;   
}

.calendar {
  position: absolute;

  top: 0;
  bottom: 0;

  left: 0;
  right: 0;

  flex-wrap: nowrap;
  
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: none;
  
  scrollbar-width:thin;
}
.calendar::-webkit-scrollbar {
  position: absolute;
  display: block;
  
  width: 5px;
}
.calendar::-webkit-scrollbar-track {

  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: var(--primary-border-color);

}
.calendar::-webkit-scrollbar-thumb {
  
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-radius: 5px;

  border-style: solid;
  border-width: 1px;
  border-right-width: 0;
  border-color: var(--primary-border-color);

  background: var(--primary-gradient);
  background-size: 100%;

  transition: all 0.30s var(--app-timing-bezier);
}
.calendar::-webkit-scrollbar-thumb:hover {
  background-position: right;
}

/* CALENDAR GRID */
.calendarGrid {
  position: absolute;
  z-index: 10;

  top: 0;
  left: 0;
  right: 0;

  min-height: 100%;

  display: flex;
  flex-direction: row;
}
.calendarRow {  
  transition: 
    all 0.3s var(--app-timing-bezier);
}
.calendarColumn {
  transition: 
    all 0.3s var(--app-timing-bezier);
}

/* CALENDAR EVENTS */
.calendarEvents {
  position: absolute;
  z-index: 30;

  pointer-events: visible;
}
.calendarEvent {
  --background-position-x: -50px;

  position: absolute;
  display: flex;
  flex-direction: column;

  z-index: 100;
  
  height: min-content !important;

  padding-top: 6pt;
  padding-left: 6pt;

  border-radius: 6pt;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  border-style: solid;
  border-width: 2px;
  border-color: var(--secondary-bg-color);

  color: var(--app-white);

  text-align: left;

  font-size: 9pt;
  font-weight: 400;
  /* offset-x | offset-y | blur-radius | color */
  text-shadow: 0px 1px 1px black;

  overflow: hidden;
  white-space:break-spaces;
  text-overflow: ellipsis;

  user-select: none;
  cursor: pointer;

  outline-style: solid !important;
  outline-width: 4px !important;
  outline-color: transparent !important;
  outline-offset: -3px !important;
  
  transition: 
    all 0.3s var(--app-timing-bezier), 
    border-color 0.3s var(--app-timing-bezier), 
    box-shadow 0.3s var(--app-timing-bezier),

    outline-color 0.3s var(--app-timing-bezier),

    left 0.3s var(--app-timing-bezier),
    min-width 0.3s var(--app-timing-bezier),
    width 0.3s var(--app-timing-bezier),

    top 0.3s var(--app-timing-bezier),
    min-height 0.3s var(--app-timing-bezier),
    height 0.3s var(--app-timing-bezier)
  ;
}
.calendarEvent:active {
  scale: 0.95;
}

.calendarEvent:hover,
.calendarEvent:focus,
.calendarEvent:focus-visible
{
  min-width: max-content !important;
  height: auto !important;
  max-height: unset !important;

  z-index: 101;  

  border-color: var(--secondary-bg-color);

  outline-style: solid !important;
  outline-width: 4px !important;
  outline-color: var(--primary-color) !important;
  outline-offset: -3px !important;
}
.calendarEvent:hover *,
.calendarEvent:focus *,
.calendarEvent:focus-visible * {
  outline: none !important;
}
:global(:root.light) .calendarEvent:hover {
  /* box-shadow: var(--material-box-shadow-1); */
}
.calendarEvent::after {
  --material-box-shadow-1: 0 0px 1px rgba(0,0,0,0.06), 0 1px 1px rgba(0,0,0,0.06);

  content: '';
  position: absolute;
  z-index: -1;
  inset: -1px;

  left: 0;
  right: 0;
  height: 4px;

  box-sizing: border-box;

  /* border-radius: 2px; */
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  background: var(--primary-gradient), var(--secondary-bg-color);
  background-size: 500%;
  background-position: left;

  box-shadow: var(--material-box-shadow-1);

  opacity: 1;

  transition: 
    all 0.9s var(--app-timing-bezier),
  ;
}
.calendarEvent:hover::after,
.calendarEvent:focus::after,
.calendarEvent:focus-visible::after
{
  opacity: 0;
  outline: none !important;
}

.calendarEvent::before {
  content: '';
  position: absolute;
  z-index: -2;
  inset: 0;
  bottom: -1px;

  background-image: var(--primary-gradient);
  background-size: 500%;
  background-position: -2px;

  border-radius: 0pt;
  border-style: solid;
  border-width: 0px;
  border-color: transparent;

  opacity: 0.85;

  transition: all 0.9s var(--app-timing-bezier);
}
.calendarEvent:hover::before
{
  opacity: 1;
  background-position-x: 70%;
  outline: none !important;
}
.calendarEvent:focus::before,
.calendarEvent:focus-visible::before {
  opacity: 0.95;
  background-position-x: 70%;
  outline: none !important;
}
.calendarEvent * {
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none !important;
}
.calendarEventHeader {
  font-weight: 900;
  line-height: 1em;
  min-height: 1em;
}

/* CALENDAR TIME INDICATOR */
.calendarTimeIndicator {
  --material-box-shadow-indicator: 0 0.5px 0px rgba(255,130,130,0.12), 0 -1px 1px rgba(255,0,0,0.74);

  position: absolute;
  z-index: 35;

  background-color: red;
  
  border-radius: 1px;
  box-shadow: var(--material-box-shadow-indicator);

  transition: 
    all 0.3s var(--app-timing-bezier);
}
.calendarTimeIndicator::after {
  --handle-size: 14pt;

  content: '';
  position: absolute;

  bottom: calc(50% - var(--handle-size));
  right: calc(50% - var(--handle-size) / 2);

  color: red;
  font-size: var(--handle-size);

  transform: scaleX(4) scaleY(0.8) translateY(-2px);
  
  background-color: transparent;
  text-shadow: var(--material-box-shadow-indicator);
}

/* CALENDAR TIME COLUMN */
.calendarTimeColumn {
  position: absolute;
  z-index: 40;
  top: -1px;
  bottom: -1px;
  left: 0;

  border: 1px solid var(--primary-border-color);
  border-left: 0;
  border-top-right-radius: 12pt; 
  border-bottom-right-radius: 12pt; 

  background-color: var(--secondary-bg-color);

  transition: all 0.3s var(--app-timing-bezier);

  overflow: hidden;
  overflow-y: hidden;
  overscroll-behavior: none;
  scrollbar-width:none;

  user-select: none;
  pointer-events:none;
}
.calendarTimeColumn::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}
/* LIGHT MODE */
:global(:root.light) .calendarTimeColumn,
:global(:root.light) .calendarTimeColumn,
:global(:root.light) .calendarTimeColumn {
  /* box-shadow: var(--material-box-shadow-1);
  -webkit-box-shadow: var(--material-box-shadow-1);
  -moz-box-shadow: var(--material-box-shadow-1); */
}
.calendarTimeColumnCell {
  position: relative;
  display: flex;

  padding-right: 10px;
  padding-bottom: 5px;
  
  justify-content:right;
  align-items: center;

  transform: translateY(50%);

  text-transform: lowercase;
  text-align: right;
  font-family: 'DM Sans', monospace;
  font-size: 8pt;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 1pt;

  white-space: nowrap;
  
  transition: all 0.3s var(--app-timing-bezier);
}
.calendarTimeColumnCell::after {
  content: '';
  position: absolute;

  inset: 0;
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;

  height: 2px;
  width: 10%;

  background-color: var(--primary-border-color);
  
  transition: all 0.3s var(--app-timing-bezier);
}
.calendarTimeColumnCell:last-child::after {
  height: 0;
}

/* CALENDAR HEADER BAR */
.calendarHeaderBarWrapper {
  position: absolute;
  z-index: 45;
  inset: 0;
  pointer-events:none;
}
.calendarHeaderBar {
  position: absolute;
  inset: 0;
  
  display: flex;
  min-width: max-content;

  background-color: var(--secondary-bg-color);

  box-shadow: var(--material-box-shadow-1);
  -webkit-box-shadow: var(--material-box-shadow-1);
  -moz-box-shadow: var(--material-box-shadow-1);

  border-bottom: 1px solid var(--primary-border-color);

  transition: all 0.3s var(--app-timing-bezier);
  
  pointer-events:auto;
}
.calendarHeaderBar:hover,
.calendarHeaderBar:focus,
.calendarHeaderBar:focus-within {
  /* box-shadow: var(--material-box-shadow-2);
  -webkit-box-shadow: var(--material-box-shadow-2);
  -moz-box-shadow: var(--material-box-shadow-2); */
}
/* LIGHT MODE */
:global(:root.light) .calendarHeaderBar {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
:global(:root.light) .calendarHeaderBar:hover,
:global(:root.light) .calendarHeaderBar:focus,
:global(:root.light) .calendarHeaderBar:focus-within {
  /* box-shadow: var(--material-box-shadow-1);
  -webkit-box-shadow: var(--material-box-shadow-1);
  -moz-box-shadow: var(--material-box-shadow-1); */
}

.calendarTimeZone {
  position: relative;
  display: flex;
  
  padding-right: 10px;
  
  justify-content: right;
  align-items: center;

  font-family: 'DM Sans', sans-serif;
  font-size: 8pt;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 1pt;
  white-space: nowrap;
}
.calendarTimeZone::after {
  content: '';
  position: absolute;
  
  inset: 0;
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;

  background-color: var(--primary-border-color);
  
  transition: all 0.3s var(--app-timing-bezier);
}
.calendarHeader {
  position: relative;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  color: var(--secondary-text-color);

  text-transform: uppercase;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 8pt;
  font-style: italic;
  letter-spacing: 0.5pt;
  
  user-select: auto;

  transition: all 0.3s var(--app-timing-bezier);
}
.calendarHeaderDay{

}
.calendarHeaderDate {
  position: relative;
  
  z-index: 0;

  display: flex;

  align-items: center;
  justify-content: center;

  font-size: 14pt;
  font-style: normal;

  transition: all 0.3s var(--app-timing-bezier);
}
.calendarHeaderTodayCircle {

  z-index: -1;

  width: 16pt;
  height: 16pt;

  border-radius: 100%;
  
  background-color: red;
}

/* CALENDAR ZOOM SLIDER */
.calendarZoomSliderWrapper {
  position: absolute;
  z-index: 35;

  right: 12pt;
  bottom: 12pt;

  min-width: min-content;
  width: min-content;
  height: min-content;

  border-radius: 24pt;
  border: 1px solid var(--primary-border-color);

  background-color: var(--primary-bg-color);

  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;

  user-select: none;
  -moz-user-select: none;

  box-shadow: var(--material-box-shadow-1);
  -webkit-box-shadow: var(--material-box-shadow-1);
  -moz-box-shadow: var(--material-box-shadow-1);
  
  transition: all .30s var(--app-timing-bezier);
}
.calendarZoomSliderWrapper:hover,
.calendarZoomSliderWrapper:focus {
  scale: 1.10
}
.calendarZoomSliderWrapper:active {
  scale: 1.05
}

.calendarZoomSliderWrapper button {
  width: 50px;
  aspect-ratio: 1 / 1;
  font-size: 14pt;
  cursor: pointer;
}
.calendarZoomSliderWrapper button:hover,
.calendarZoomSliderWrapper button:focus {
  background-color: var(--secondary-bg-color);
}
.calendarZoomSliderText {
  all:unset;
  -moz-appearance: textfield;
}
.calendarZoomSliderText::-webkit-outer-spin-button,
.calendarZoomSliderText::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.calendarLoadingIndicator {
  box-shadow: var(--material-box-shadow-1);
  -webkit-box-shadow: var(--material-box-shadow-1);
  -moz-box-shadow: var(--material-box-shadow-1);
  
  /* transition:  */
    /* all 500ms var(--app-timing-bezier), */
    /* width 50ms cubic-bezier( 0.79, 0.14, 1, 0.36); */
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}