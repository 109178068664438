.loginWrapper {
  
  z-index: 9999;

  min-width: 250px;
  width: 350px;

  backdrop-filter: blur(1px);
  border-radius: 2rem;
}
.loginPopper {

  margin-top: 6pt;

  border-radius: 2rem;
  border-width: 1px;
  border-style: solid;
  border-color: var(--secondary-bg-color);
  border-color: var(--primary-border-color);
  
  background-color: var(--secondary-bg-color);

  padding: 10pt;

  display: flex;
  
  flex-direction: column;

  align-items: center;
  
  box-shadow: var(--material-box-shadow-2);
  -webkit-box-shadow: var(--material-box-shadow-2);
  -moz-box-shadow: var(--material-box-shadow-2);
  
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  
  opacity: 0.75;

  transition:
    all 0.60s var(--app-timing-bezier);
}

.loginPopper:hover,
.loginPopper:focus,
.loginPopper:focus-within,
.loginPopper.focused {
  
  border-color: var(--primary-border-color);
  
  opacity: 1;
}

:global(:root.light) .loginPopper {
  
  box-shadow: var(--material-box-shadow-1);
  -webkit-box-shadow: var(--material-box-shadow-1);
  -moz-box-shadow: var(--material-box-shadow-1);
}

.loginHeading {
  z-index: 10;

  margin-top: 10pt;

  background-image: var(--primary-gradient);
  background-size: 300%;
  background-position: left;
  background-clip: text;
  -webkit-background-clip: text;

  color: transparent;

  letter-spacing: 0.05rem;

  text-transform: uppercase;
  text-align: center;
  font-size: 10pt;
  font-weight: 600;

  pointer-events: none;
}
.loginHeading:first-of-type {
  margin-top: 0;
}