.titlebutton2 {
  --default-font-size: 12pt;
  --button-gradient: var(--primary-gradient);
  
  /* all: unset; */
  display: flex;

  height: max-content;
  /* width: max-content; */
  flex: 1;
  min-width: min-content;
  
  position: relative;

  z-index: 200;

  margin: 0;
  padding: 0;

  text-decoration: none;

  cursor: pointer;
  user-select: none;
  
  overflow: visible;
  
  will-change: transform;
  transform-style: flat;
  transform: scale(1.0);
  transition: all 0.30s var(--app-timing-bezier), transform 0.30s var(--app-timing-bezier), background-position 1s ease;
}
.titlebutton2:hover,
.titlebutton2:focus,
.titlebutton2:focus-visible {
  outline: none;
}
/* LIGHT MODE SHADOWS */
:global(:root.light) .titlebutton2:hover,
:global(:root.light) .titlebutton2:focus,
:global(:root.light) .titlebutton2:focus-visible {
}

.title2 {
  position: relative;
  
  z-index: -99999;

  display: inline-flex;

  margin: 0;
  margin-top: -0.2rem;
  padding: 0;

  background-image: var(--primary-gradient);
  background-size: 300%;
  background-position: left;
  background-clip: text;
  -webkit-background-clip: text;

  color: transparent;
  
  line-height: 1em;
  letter-spacing: -0.15rem;
  
  text-transform:uppercase;
  text-decoration: none;

  font-size: 5rem;
  font-weight: 900;
  font-style:normal;
  font-family: 'JetBrains Mono', monospace;

  overflow: visible;
  
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  transition: all 0.30s var(--app-timing-bezier);
}
.titlebutton2:hover .title2,
.titlebutton2:focus .title2,
.titlebutton2:focus-visible .title2 {
  
  /* background-position-x: var(--background-position-x); */
  background-position-x: right;
  /* letter-spacing: -0.25rem; */
  /* letter-spacing: 0; */
  font-weight: 600;
}

@keyframes rotate-bg {
  0% {
    left: 20%;
    background-position-x: left;
  }
  50% {
    left: 80%;

    background-position-x: right;
  }
  100% {
    left: 20%;

    background-position-x: left;
  }
}
@keyframes rotate-blob {
  0% {
    rotate: 0deg;
  }
  50% {

    rotate: 180deg;
  }
  100% {
    
    rotate: 360deg;
  }
}
.titlebutton2::before {
  content: '';
  position: absolute;
  z-index: -99999;
  inset: 0;
  left: 50%;
  top: auto;
  bottom: -200%;
  translate: -50% -50%;

  width: 128pt;
  /* max-height: 30%; */

  aspect-ratio: 1.5 / 1;

  border-radius: 100%;

  background: var(--primary-gradient);
  background-size: 400%;
  background-position-x: left;

  filter: blur(50px);
  opacity: 0.25;
  
  animation: 15s ease-in-out infinite normal rotate-bg, 10s ease-in-out infinite normal rotate-blob;
}
