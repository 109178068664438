* {
  max-width: 100%;
}
button {
  all: unset;
}
button:hover,
button:focus,
button:focus-visible {
  outline: 0;
}
button[disabled] { 
  cursor:default;
}
