.wrapper {
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  padding: 1rem 1rem;
  
  display: flex;
  flex-direction: column;
  text-align: center;

  background-color: transparent;
}

.topbar {
  width: 100%;
  z-index: 0;
  overflow: visible;
}
.topbarWrapper {
  display: flex;
  align-items: center;
  overflow: visible;
  min-width: max-content;
  flex-grow: 0;
}

.content {
  flex: 1;

  position: relative;

  margin-top: 6pt;
  display: flex;
}

.contentWrap {
  
  position: relative;

  width: 100%;
  min-width: min-content;
  
  border-radius: 24pt;
  border: 1px solid var(--primary-border-color);

  background-color: var(--secondary-bg-color);

  display: flex;
  flex-direction: row;

  overflow: hidden;
  
  box-shadow: var(--material-box-shadow-2);
  -webkit-box-shadow: var(--material-box-shadow-2);
  -moz-box-shadow: var(--material-box-shadow-2);

  transition: all 0.3s var(--app-timing-bezier);
}
.contentWrap:hover,
.contentWrap:focus,
.contentWrap:focus-within {

  box-shadow: var(--material-box-shadow-1-hover);
  -webkit-box-shadow: var(--material-box-shadow-1-hover);
  -moz-box-shadow: var(--material-box-shadow-1-hover);
}
:global(:root.light) .contentWrap {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
:global(:root.light) .contentWrap:hover,
:global(:root.light) .contentWrap:focus,
:global(:root.light) .contentWrap:focus-within {
  box-shadow: var(--material-box-shadow-1);
  -webkit-box-shadow: var(--material-box-shadow-1);
  -moz-box-shadow: var(--material-box-shadow-1);
}

.outlet {
  flex: 1;
  position: relative;
  z-index: 0;
  display: flex;
}