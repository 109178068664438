.button {
  --default-font-size: 12pt;
  --button-gradient: var(--primary-gradient);
  
  /* all: unset; */
  display:inline-block;

  height: max-content;
  width: max-content;
  min-width: min-content;
  
  position: relative;

  z-index: 200;

  margin: 0;
  padding: 0;
  
  border-radius: 8em;
  overflow: hidden;

  background-repeat:no-repeat;
  background-image: var(--button-gradient);
  background-size: 300%;
  background-position: left;

  color: var(--app-white);
  text-decoration: none;

  font-size: var(--default-font-size);
  font-weight: 500;
  font-family: inherit;

  cursor: pointer;
  user-select: none;
  
  will-change: transform;
  transform-style: flat;
  transform: scale(1.0);
  transition: all 0.15s ease, transform 0.30s cubic-bezier(0.5,0,0,1), background-position 1s;
}
.button:hover,
.button:focus,
.button:focus-visible {
  outline: none;
  background-position: right;
  transform: scale(1.05);
}

.buttontext {
  pointer-events: none;
  
  border-radius: 8em;

  padding: 0.6em 1.2em;

  color: white;
  
  font-size: var(--default-font-size);

  text-overflow: ellipsis;
  white-space: nowrap;
  
  text-decoration: none;
}

.buttoninner {
  position: absolute;
  pointer-events: none;
  z-index: 1;

  inset: 0.12em;

  align-self: center;
  
  border-radius: 8em;

  background-color: white;

  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);

  color: black;
  
  text-decoration: none;
  
  will-change: transform;
  transition: transform 0.60s; /*cubic-bezier(1,0,0,1);*/
}
.button:hover > .buttoninner,
.button:focus > .buttoninner,
.button:focus-visible > .buttoninner {
  transform: scaleX(0.95);
  box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.15);
}

.buttontextabsolute {
  
  position: absolute;
  pointer-events:fill;
  z-index: 1;

  inset: 0;
  
  padding: 0.6em 1.2em;

  background-image: var(--button-gradient);
  background-size: 300%;
  background-position: left;
  background-clip: text;
  -webkit-background-clip: text;

  color: transparent;
  -webkit-text-fill-color: transparent;
  
  text-decoration: none;

  transition: background-position 0.5s;
}
.button:hover > .buttontextabsolute,
.button:focus > .buttontextabsolute,
.button:focus-visible > .buttontextabsolute {
  background-position: right;
}