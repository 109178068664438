@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.gear {
  color: var(--primary-border-color);


  animation-name: fa-spin;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: reverse;
  animation-play-state: paused;

  transition:
    all 0.60s var(--app-timing-bezier);
}

.focused > .gear,
:focus > .gear,
:focus-within > .gear,
:hover > .gear,
.gear:hover,
.gear:focus,
.gear:focus-visible {
  animation-play-state: running;
  color: var(--primary-color);
}

.settingsWrapper {
  min-width: 250px;

  backdrop-filter: blur(1px);
  border-radius: 2rem;
}
.settings {

  margin-top: 6pt;

  border-radius: 2rem;
  border-width: 1px;
  border-style: solid;
  border-color: var(--secondary-bg-color);
  border-color: var(--primary-border-color);
  

  background-color: var(--secondary-bg-color);

  padding: 10pt;

  display: flex;
  
  flex-direction: column;

  align-items: center;
  
  box-shadow: var(--material-box-shadow-2);
  -webkit-box-shadow: var(--material-box-shadow-2);
  -moz-box-shadow: var(--material-box-shadow-2);
  
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  
  opacity: 0.75;

  transition:
    all 0.60s var(--app-timing-bezier);
}

.settings:hover,
.settings:focus,
.settings:focus-within,
.settings.focused {
  
  border-color: var(--primary-border-color);
  
  opacity: 1;
}

:global(:root.light) .settings {
  
  box-shadow: var(--material-box-shadow-1);
  -webkit-box-shadow: var(--material-box-shadow-1);
  -moz-box-shadow: var(--material-box-shadow-1);
}

.settingsHeading {
  z-index: 10;

  margin-top: 10pt;

  background-image: var(--primary-gradient);
  background-size: 300%;
  background-position: left;
  background-clip: text;
  -webkit-background-clip: text;

  color: transparent;

  letter-spacing: 0.05rem;

  text-transform: uppercase;
  text-align: center;
  font-size: 10pt;
  font-weight: 600;

  pointer-events: none;
}
.settingsHeading:first-of-type {
  margin-top: 0;
}

/* COLOR PICKER */
.colorPickerWrapper {
  all: unset;
  
  --handle-size: 24pt;

  display: flex;
  position:relative;

  z-index: 10;
  
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  border-radius: 24pt;

  padding-top: 5%;
  padding-bottom: 5%;
  
  box-shadow: none;

  transition: 
    all 0.60s var(--app-timing-bezier);
}
.colorPicker {
  all: unset;

  margin-inline: 5pt;

  width: var(--handle-size);
  height: var(--handle-size);

  border-radius: var(--handle-size);

  background-color: black;
  background-image: var(--orange-gradient);

  box-shadow: var(--material-box-shadow-2);
  -webkit-box-shadow: var(--material-box-shadow-2);
  -moz-box-shadow: var(--material-box-shadow-2);

  opacity: 0.25;

  cursor: pointer;

  transition: all 0.90s var(--app-timing-bezier);
}
.colorPicker:hover,
.colorPicker:focus,
.colorPicker:focus-within,
.colorPicker.focused {
  opacity: 1;
  box-shadow: var(--material-box-shadow-4);
  -webkit-box-shadow: var(--material-box-shadow-4);
  -moz-box-shadow: var(--material-box-shadow-4);
}
.colorPicker:active {
  scale: 0.95;
}

:global(:root.light) .colorPicker {
  box-shadow: var(--material-box-shadow-1);
  -webkit-box-shadow: var(--material-box-shadow-1);
  -moz-box-shadow: var(--material-box-shadow-1);
}
:global(:root.light) .colorPicker:hover,
:global(:root.light) .colorPicker:focus,
:global(:root.light) .colorPicker:focus-within,
:global(:root.light) .colorPicker.focused {
  box-shadow: var(--material-box-shadow-2);
  -webkit-box-shadow: var(--material-box-shadow-2);
  -moz-box-shadow: var(--material-box-shadow-2);
}


/* DARK MODE VS LIGHT MODE */

/* SURROUNDING PAD */
.toggleWrapper {
  all: unset;
  
  --handle-size: 24pt;

  display: flex;
  position: relative;

  z-index: 10;
  
  flex-direction: column;
  align-items: center;

  width: 50%;

  border-radius: 2rem;

  margin-top: 2pt;
  padding: 5%;
  
  box-shadow: none;

  transition: 
    all 0.6s var(--app-timing-bezier);

  overflow: visible;

  cursor: pointer;
}
.toggleWrapper::after{
  content: '';
  position: absolute;
  inset: 0;

  border-radius: 2rem;
  opacity: 0.2;

  transition: all 0.3s var(--app-timing-bezier);
}
.toggleWrapper:hover::after {
  background-color: var(--primary-border-color);
}

/* INVISIBLE CHECKBOX INPUT */
.toggleWrapper input {
  opacity: 0;
  position: absolute;
}

/* BACKGROUND OF SWITCH */
.toggleWrapper input + label {
  position: relative;
  z-index: 2;
  
  height: var(--handle-size);
  min-width: 50px;
  max-width: 100px;
  width: 100%;
  
  border-radius: var(--handle-size);
  box-shadow: var(--material-box-shadow-1);

  transition: all 0.60s var(--app-timing-bezier);
}

.toggleWrapper:active input + label {
  scale: 0.95
}

.toggleWrapper input + label::before {
  content: '';
  
  position: absolute;
  inset: 0;

  border-radius: var(--handle-size);
  background: var(--primary-gradient);

  opacity: 0;

  transition: 
    all .60s var(--app-timing-bezier);
  
  cursor: pointer;
}

:global(:root.light) .toggleWrapper input + label::before {
  opacity: 1;
}

/* THIS IS THE HANDLE */
.toggleWrapper input + label::after {
  content: "";
  position: absolute;
  display: block;

  left: 0;
  right: 100%;

  height: var(--handle-size);
  width: var(--handle-size);
  
  border-radius: 100%;
  /* background: var(--app-purple); */
  /* background: var(--gray-gradient); */
  background-color: var(--primary-border-color);
  background-size: 300%;

  box-shadow: var(--material-box-shadow-2);
  -webkit-box-shadow: var(--material-box-shadow-2);
  -moz-box-shadow: var(--material-box-shadow-2);

  animation: gradient-animation 5s infinite alternate;
  animation-play-state: running;

  opacity: 1;
  
  transition: 
    all .60s var(--app-timing-bezier);
  
  cursor: pointer;
}

.toggleWrapper input:checked + label::after {
  left: calc(100% - var(--handle-size));
  right: 0;
  background-color: var(--primary-color);
}

.toggleWrapper:active input + label::after {
  scale: 0.95
}

.toggleWrapper:hover input + label::after {
  box-shadow: var(--material-box-shadow-4);
  -webkit-box-shadow: var(--material-box-shadow-4);
  -moz-box-shadow: var(--material-box-shadow-4);
}


:global(:root.light) .toggleWrapper input + label::after {
  box-shadow: var(--material-box-shadow-1);
  -webkit-box-shadow: var(--material-box-shadow-1);
  -moz-box-shadow: var(--material-box-shadow-1);
}


:global(:root.light) .toggleWrapper:hover input + label::after {
  box-shadow: var(--material-box-shadow-2);
  -webkit-box-shadow: var(--material-box-shadow-2);
  -moz-box-shadow: var(--material-box-shadow-2);
}


.logbuttonWrapper {
  position: relative;

  aspect-ratio: 1 / 1;

  height: 24pt;

  border-radius: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: var(--primary-border-color);

  background-color: var(--secondary-bg-color);

  padding: 8pt;

  cursor: pointer;

  opacity: 1;

  transition: 
    all 0.60s var(--app-timing-bezier),
    background-color 0.60s var(--app-timing-bezier);
}
.logbuttonWrapper:hover, 
.logbuttonWrapper:focus, 
.logbuttonWrapper:focus-within,
.logbuttonWrapper.focused {
  
  border-color: var(--primary-color);
  opacity: 1;
}

.logbuttonWrapper:active {
  scale: 0.95
}

:global(:root.light) .wrapper {
  box-shadow: var(--material-box-shadow-1);
  -webkit-box-shadow: var(--material-box-shadow-1);
  -moz-box-shadow: var(--material-box-shadow-1);
}

.logbutton {
  color: var(--primary-border-color);

  font-size: 24pt !important;

  transition:
    all 0.60s var(--app-timing-bezier);
}

:hover > .logbutton {

  color: var(--primary-color)
}
