/** !!IMPORTANT **/
/** Entire app is built in dark mode, with light mode being the exception **/
/** Please stick to this convention **/

:root {
  overflow: hidden;

  /* FONTS */
  font-family: "DM Sans", sans-serif;
  font-weight: 400;

  /* SETUP */
  color-scheme: light dark;
  color: var(--primary-text-color);
  background-color: var(--primary-bg-color);
  /* background-image: var(--primary-gradient); */

  font-size: 12pt;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  /* TIMINGS */
  --app-timing-bezier: cubic-bezier(.25,.8,.25,1);

  transition: 
    all 0.30s var(--app-timing-bezier);

  /* CONSTANT COLORS */
  --app-white: #fafafa;
  --app-black:  #1c1b22;


  /* VARIABLE COLORS */
  --primary-bg-color: #1c1b22;
  --secondary-bg-color: #383838;

  --primary-border-color: #666666;

  --primary-text-color: #ffffff;
  --secondary-text-color: #bdbdbd;

  /* SHADOWS */
  --material-box-shadow-1: 0 0px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  /* --material-box-shadow-1-hover: 0 0px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); */
  --material-box-shadow-1-hover: 0 0px 12px rgba(0,0,0,0.125), 0 10px 7px rgba(0,0,0,0.11);
  --material-box-shadow-2: 0 0px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  --material-box-shadow-3: 0 0px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  --material-box-shadow-4: 0 0px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  --material-box-shadow-5: 0 0px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);

  /* CONSTANT GRADIENTS */
  
  --app-orange: #fd6437;
  --orange-gradient: linear-gradient(
    45deg,
    #f84141,
    #fb533c,
    #fd6437,
    #ff7433,
    #ff8330,
    #ff912f,
    #fe9f30
    /* #fdac33, */
    /* #fbb939, */
    /* #f8c641 */
  );

  --app-pink: #ff5db1;
  --pink-gradient: linear-gradient(
    45deg, 
    #ef017c,
    rgb(249, 79, 146),
    rgb(253, 114, 114)
    /* #ff5db1 */
  );

  --app-green: #7baa0a;
  --green-gradient: linear-gradient(
    235deg,
    #aecb08,
    #94ba09,
    #7baa0a,
    #64990b,
    #4e890b,
    #38780a
  );

  --gray-gradient: linear-gradient(
    235deg,
    #d9d9d9,
    #c7c7c7,
    #b6b6b6,
    #a5a5a5,
    #949494,
    #838383,
    #737373,
    #636363,
    #545454,
    #454545
  );

  --primary-color: var(--app-orange);
  --primary-gradient: var(--orange-gradient);
}

/* LIGHT MODE COLORS */
:root.light {
  
  --primary-bg-color: #ffffff;
  --secondary-bg-color: #f9f9f9;

  --primary-border-color: #dadce0;

  --primary-text-color: #1c1b22;
  --secondary-text-color: #383838;
}

* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;   
}

body {
  margin: 0;

  min-height: 100vh;
  height: 100vh;
  width: 100%;

  background-color: transparent;

  display: flex;
}

#root {
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}