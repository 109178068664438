.content {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 10pt;
  text-align: left;
  
  max-height: 100%;

  word-wrap: break-word;
  word-break: keep-all;

  overflow: visible;

}

.titleSection {

  position: relative;
  display: flex;
  flex-direction: row;

  margin-top: 10pt;
}

.titleSection::after {
  content: '';
  position: absolute;

  background-color: var(--primary-border-color);

  height: 1px;
  
  bottom: 0;
  left: 0;
  right: 0;
}

.body {

  padding-top: 10pt;

  overflow: scroll;
  overflow-x: visible;
}

.content h1 {

  min-width: min-content;

  margin: 0;

  background: var(--primary-gradient), var(--primary-color);
  background-size: 300%;
  background-position: left;
  background-clip: text;
  -webkit-background-clip: text;

  color: transparent;

  vertical-align:middle;

  font-size: 3rem;
  
}

@keyframes gradient-animation {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

.content h3 {

  margin: 0;
  margin-right: 10pt;

  max-width: max-content;
  
  border-radius: 0.5rem;

  background: var(--primary-gradient);
  background-size: 300%;
  background-position: left;

  padding: 2pt;

  color: white;
  
  text-shadow: var(--material-box-shadow-1);

  animation-name: gradient-animation;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: running;
  animation-direction: alternate;
}

.buttonGroup {
  display: flex;

  flex-direction: row;

  max-width: max-content;
  
  overflow: visible;
}

.buttonGroup button {
  --active-color: var(--secondary-text-color);

  position: relative;

  margin-block: 6pt;
  margin-right: 6pt;

  border-width: 2px;
  border-style: solid;
  border-radius: 0.5rem;
  border-color: var(--primary-border-color);


  padding-block: 5pt;
  padding-inline: 10pt;

  color: var(--secondary-text-color);

  font-weight: 600;
  font-family: 'JetBrains mono', monospace;
  
  text-align: center;
  user-select: none;

  cursor: pointer;

  overflow: hidden;

  transition:
    all 0.30s var(--app-timing-bezier);
}

.buttonGroup button:hover,
.buttonGroup button:focus,
.buttonGroup button:focus-visible {

  border-color: var(--active-color);

  color: white;

  text-shadow: var(--material-box-shadow-1);
}

.buttonGroup button:active {
  scale: 0.95
}

.buttonGroup button::before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: -1px;

  opacity: 0.75;
  
  transition:
    all 0.30s var(--app-timing-bezier),
    border-color 0s var(--app-timing-bezier);
}

.buttonGroup button:hover::before,
.buttonGroup button:focus::before,
.buttonGroup button:focus-visible::before {

  background-color: var(--active-color);
}

.recursiveJSONListItem {
  position: relative;
  overflow: hidden;

}

.recursiveJSONListItem:hover::before,
.recursiveJSONListItem:focus::before,
.recursiveJSONListItem:focus-visible::before {
  content: '';
  z-index: -1;
  position: absolute;
  inset: 0;
  background-color: var(--primary-border-color);
  opacity: 0.25;
}