.wrapper {
  --accent-color: var(--primary-color);

  position: relative;

  aspect-ratio: 1 / 1;

  height: 24pt;

  border-radius: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: var(--primary-border-color);

  background-color: var(--secondary-bg-color);

  padding: 8pt;
  
  box-shadow: var(--material-box-shadow-2);
  -webkit-box-shadow: var(--material-box-shadow-2);
  -moz-box-shadow: var(--material-box-shadow-2);

  cursor: pointer;

  opacity: 0.4;

  transform-style: preserve-3d;

  transition: 
    all 0.60s var(--app-timing-bezier),
    background-color 0.60s var(--app-timing-bezier);
}

.wrapper:hover, 
.wrapper:focus, 
.wrapper:focus-within,
.wrapper.focused {
  
  border-color: var(--accent-color);
  
  box-shadow: var(--material-box-shadow-4);
  -webkit-box-shadow: var(--material-box-shadow-4);
  -moz-box-shadow: var(--material-box-shadow-4);

  opacity: 1;
}

.wrapper:active {
  scale: 0.95
}

:global(:root.light) .wrapper {
  box-shadow: var(--material-box-shadow-1);
  -webkit-box-shadow: var(--material-box-shadow-1);
  -moz-box-shadow: var(--material-box-shadow-1);
}

:global(:root.light) .wrapper:hover, 
:global(:root.light) .wrapper:focus, 
:global(:root.light) .wrapper:focus-within {

}

.wrapper * {
  color: var(--primary-border-color);

  font-size: 24pt !important;

  transition:
    all 0.60s var(--app-timing-bezier);
}

.wrapper:hover *, 
.wrapper:focus * , 
.wrapper:focus-within *,
.wrapper.focused * {
  color: var(--accent-color);
}