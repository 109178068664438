.sidebar {
  --sidebar-border-radius: 12pt;
  --sidebar-width: 250px;
  
  min-width: var(--sidebar-width);
  max-width: var(--sidebar-width);

  min-height: unset !important;

  position: relative;

  z-index: 200;

  border-radius: var(--sidebar-border-radius);

  overflow: visible;

  transition: all 0.60s var(--app-timing-bezier);
}

@keyframes gradient-animation {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

/* THIS IS TO COVER UP THE SPACE BEHIND SIDEBAR CAUSED BY CURVATURE */
.sidebar::before {
  content: '';
  position: absolute;
  inset: 0;
  border: 0;
  right: -1px;
  bottom: -1px;
  background-color: var(--secondary-bg-color);
  transition: all 0.30s var(--app-timing-bezier);
  
  border-style: solid;
  border-bottom-width: 1px;
  border-color: var(--primary-border-color);
}
:global(:root.light) .sidebar::before {
}
.sidebar::after {
  content: '';
  position: absolute;
  z-index: 0;
  inset: -1px;
  left: 0;
  background-color: var(--secondary-bg-color);
  
  border-style: solid;
  border-top-width: 1px;
  border-left: 0;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-color: var(--primary-border-color);
  
  border-radius: calc(var(--sidebar-border-radius) + 1px);

  box-shadow: var(--material-box-shadow-2);
  -webkit-box-shadow: var(--material-box-shadow-2);
  -moz-box-shadow: var(--material-box-shadow-2);

  transition: all 0.3s var(--app-timing-bezier);
}
.sidebar:hover::after,
.sidebar:focus::after,
.sidebar:focus-within::after {
  box-shadow: var(--material-box-shadow-1-hover);
  -webkit-box-shadow: var(--material-box-shadow-1-hover);
  -moz-box-shadow: var(--material-box-shadow-1-hover);
}
/* LIGHT MODE */
:global(:root.light) .sidebar::after,
:global(:root.light) .sidebar::after,
:global(:root.light) .sidebar::after {
  box-shadow: var(--material-box-shadow-1);
  -webkit-box-shadow: var(--material-box-shadow-1);
  -moz-box-shadow: var(--material-box-shadow-1);
}
:global(:root.light) .sidebar:hover::after,
:global(:root.light) .sidebar:focus::after,
:global(:root.light) .sidebar:focus-within::after {
  box-shadow: var(--material-box-shadow-2);
  -webkit-box-shadow: var(--material-box-shadow-2);
  -moz-box-shadow: var(--material-box-shadow-2);
}

.sidebarInner {

  width: 100%;

  display: flex;
  position: absolute;
  inset: 0;
  flex-direction: column;

  align-items: center;
  justify-content: stretch;

  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;

  padding-top: 12pt;
  padding-bottom: 12pt;
}
.sidebarInner::-webkit-scrollbar {
  display: none;
}

.sidebarHeading {
  z-index: 10;

  /* margin-top: 12pt; */
  /* margin-bottom: 0.5rem; */

  /* padding-left: 25%; */
  /* color: var(--secondary-text-color);
  color: var(--app-orange); */

  background: var(--primary-gradient);
  background-size: 300%;
  background-position: left;
  background-clip: text;
  -webkit-background-clip: text;

  color: transparent;

  animation: gradient-animation 5s infinite alternate;
  animation-play-state: running;

  letter-spacing: 0.05rem;

  text-transform: uppercase;
  text-align: center;
  font-size: 10pt;
  font-weight: 600;
}
.sidebarHeading:hover {
  animation-play-state: paused;
}
.sidebarHeadingDark {
  background-image: none !important;
  background-color: var(--primary-border-color) !important;
}

.sidebarTabGroup {

  margin-left: 5%;
  margin-right: 5%;
  width: 90%;

  overflow: visible;

  transition:
    all 0.60s var(--app-timing-bezier);
}

.sidebarTab {
  
  display: flex;
  position: relative;
  
  z-index: 0;

  margin-top: 0.3rem;

  min-height: 3em;
  
  border-radius: 0.5rem;

  border-width: 0px;
  border-style: solid;
  border-color: transparent;

  overflow: hidden;
  
  align-items: center;
  justify-content: flex-start;
  
  text-decoration: none;
  font-size: 12pt;

  transition: 
    border-radius 1.20s var(--app-timing-bezier),
    all 0.60s var(--app-timing-bezier);
}
.sidebarTab:first-of-type {
  margin-top: 0;
}
.sidebarTab:hover,
.sidebarTab:focus,
.sidebarTab:focus-visible {

  border-radius: 1rem;
  
  background-color: var(--secondary-bg-color);

  box-shadow: var(--material-box-shadow-1);
  -webkit-box-shadow: var(--material-box-shadow-1);
  -moz-box-shadow: var(--material-box-shadow-1);
}

.sidebarTab:active {
  border-radius: 1rem;
}

.sidebarTab.selected {
  
  border-radius: 1rem;

  text-shadow: 0px 1px 1px var(--app-black);

  box-shadow: var(--material-box-shadow-2);
  -webkit-box-shadow: var(--material-box-shadow-2);
  -moz-box-shadow: var(--material-box-shadow-2);
}
.sidebarTab.selected:hover,
.sidebarTab.selected:focus,
.sidebarTab.selected:focus-visible {

  border-radius: 0.5rem;

  box-shadow: var(--material-box-shadow-4);
  -webkit-box-shadow: var(--material-box-shadow-4);
  -moz-box-shadow: var(--material-box-shadow-4);
}


/* LIGHT MODE */
:global(:root.light) .sidebarTab,
:global(:root.light) .sidebarTab,
:global(:root.light) .sidebarTab {
}
:global(:root.light) .sidebarTab:hover,
:global(:root.light) .sidebarTab:focus,
:global(:root.light) .sidebarTab:focus-within {
  box-shadow: var(--material-box-shadow-1);
  -webkit-box-shadow: var(--material-box-shadow-1);
  -moz-box-shadow: var(--material-box-shadow-1);
}
:global(:root.light) .sidebarTab.selected {
  box-shadow: var(--material-box-shadow-1);
  -webkit-box-shadow: var(--material-box-shadow-1);
  -moz-box-shadow: var(--material-box-shadow-1);
}
:global(:root.light) .sidebarTab.selected:hover,
:global(:root.light) .sidebarTab.selected:focus,
:global(:root.light) .sidebarTab.selected:focus-within {
  box-shadow: var(--material-box-shadow-2);
  -webkit-box-shadow: var(--material-box-shadow-2);
  -moz-box-shadow: var(--material-box-shadow-2);
}

/* Used for background */
.sidebarTab::before {    
  content: "";
  position: absolute;
  z-index: -1;

  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  background-image: var(--primary-gradient);
  background-color: var(--primary-border-color);
  background-position: left;
  background-size: 300%;

  opacity: 0;
  
  transition: 
    all 0.60s var(--app-timing-bezier), 
    background-position 0.60s var(--app-timing-bezier);
}
.sidebarTab:hover::before,
.sidebarTab:focus::before,
.sidebarTab:focus-visible::before {
}
.sidebarTab.selected::before { 
  /* background shows when selected class is applied */
  opacity: 1;
  background-position: right;
  
  min-width: var(--sidebar-width);
  max-width: var(--sidebar-width);
}

@keyframes scroll-text {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-40%);
  }
  100% {
    transform: translateX(0%);
  }
}
.sidebarTab > * {
  display: inline-block;

  padding-left: 10%;
  padding-right: 5%;

  align-items: center;
  justify-content: flex-start;
  
  text-decoration: none !important;
  text-align: left;

  /* overflow: hidden; */
  text-overflow: ellipsis;
  white-space: nowrap;

  color: var(--secondary-text-color);

  font-weight: 500;
  font-style: normal;
  font-family: "Poppins", sans-serif;

  transition: all 0.30s var(--app-timing-bezier);
}
.sidebarTab:hover > *,
.sidebarTab:focus > *,
.sidebarTab:focus-visible > * {

}

.sidebarTab > * > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  animation-delay: 0;
}
.sidebarTab:hover > * > .scrollText:hover,
.sidebarTab:focus > * > .scrollText:focus,
.sidebarTab:focus-visible > * > .scrollText:focus-visible {
  overflow: visible;
  text-overflow: clip;

  animation-name: scroll-text;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

.sidebarTab.selected > * {
  color: var(--app-white);
}

.colorBlobWrapper {
  position: absolute;

  inset: 0;
  border-radius: var(--sidebar-border-radius);

  overflow: hidden;
}
@keyframes rotate-blob {
  0% {
    rotate: 0deg;
  }
  50% {
    rotate: 180deg;
  }
  100% {
    rotate: 360deg;
  }
}
.colorBlob1 {
  position: absolute;
  z-index: 1;

  width: 100px;
  aspect-ratio: 1 / 2;

  border-radius: 100%;
  background: var(--primary-gradient);

  top: -15%;
  left: 50%;
  translate: -50% 0%;

  filter: blur(100px);

  opacity: 0.4;

  /* overflow: hidden; */

  animation: 20s linear infinite normal rotate-blob;
}

.colorBlob1::before {
  content: '';
  position: absolute;

  /* width: 100%; */
  /* aspect-ratio: 1 / 1; */
  /* top: -50%; */
  inset: -6%;
  top: 50%;
  aspect-ratio: 1 / 1.2;

  border-radius: 100%;

  background: var(--primary-gradient);
  
  filter:saturate(250%) brightness(250%) blur(0px);

  opacity: 0.75;
  animation: 5s linear infinite normal rotate-blob;
}
.colorBlob1::after {
  content: '';
  position: absolute;

  /* width: 100%; */
  /* aspect-ratio: 1 / 1; */
  /* top: -50%; */
  inset: -25%;
  bottom: 20%;
  aspect-ratio: 1 / 1.1;

  border-radius: 100%;

  background: var(--primary-gradient);
  
  filter:saturate(250%) brightness(100%) blur(0px);

  opacity: 0.25;
  animation: 8s linear infinite normal rotate-blob;
}

.pictureWrapper {

  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;

  border-radius: var(--sidebar-border-radius);
  overflow: hidden;

  mask-image:  
    linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1.0) 0%,
      transparent
    );
}

.picture {
  top: 0;

  /* TO CENTER IMAGE WITHOUT FADING ON BORDERS */
  margin-left: -5%;
  min-width: 110%;

  aspect-ratio: 1 / 1;

  background-size: cover;

  opacity: 0.15;

  filter: blur(6px) saturate(50%);
}

:global(:root.light) .picture {

  opacity: 0.25;
  
  filter: blur(4px) saturate(50%);
}

.picture > div {
  /* position: absolute;
  inset: 0;
  background: 
    linear-gradient(
      to top,
      var(--secondary-bg-color),
      transparent
    ); */
}

.linkGroup {
  z-index: 5;
  position: relative;

  padding-top: 5pt;

  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--primary-border-color);
}
.linkGroup a {
  position: relative;

  margin-right: 2pt;

  padding-block: 2pt;
  padding-inline: 4pt;

  color: var(--primary-color);
  font-size: 0.70rem;
  text-decoration: none;

  transition: 
    all 0.3s var(--app-timing-bezier);
}
.linkGroup a::before {
  content: '';
  z-index: -1;
  position: absolute;
  inset: 0;

  border-radius: 1em;

  background: var(--primary-border-color);

  opacity: 0;

  transition: 
    all 0.3s var(--app-timing-bezier);
}

.linkGroup a:hover::before {

  opacity: 0.25;
}

.linkGroup a:active {
  
  transform: scale(0.95);
}

.linkGroup a:last-child:hover {
  
  color: red;
}